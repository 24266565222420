import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((faq) => {
            accordionSections.forEach(function (section) {
                faq.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const videoSectionContainers = document.querySelectorAll('.link-video-section') as NodeListOf<HTMLElement>;
    if (videoSectionContainers.length) {
        import('./ts/video-link').then((videoSection) => {
            videoSectionContainers.forEach((section) => {
                videoSection.default.init(section);
            });
        });
    }

    const heroVideo = document.querySelectorAll('.hero-video') as NodeListOf<HTMLElement>;
    if (heroVideo.length) {
        import('./ts/video-link').then((videoSection) => {
            heroVideo.forEach((section) => {
                videoSection.default.init(section);
            });
        });
    }

    const logoBanners = document.querySelectorAll('.logo-section') as NodeListOf<HTMLElement>;
    if (logoBanners.length) {
        logoBanners.forEach((component) => {
            import('./ts/logo-slider').then((slidingBanner) => {
                slidingBanner.default.init(component);
            });
        });
    }

    const quoteBoxSections = document.querySelectorAll('.quote-box') as NodeListOf<HTMLElement>;
    if (quoteBoxSections.length) {
        import('./ts/quote-box').then((quoteBox) => {
            quoteBoxSections.forEach((section) => {
                quoteBox.default.init(section);
            });
        });
    }
})();
